<template>
  <div>
    <!-- Title Section -->
    <section class="title-section top">
      <div class="title-wrap">
        <!-- WITH PARTNER PROGRAM -->
        <h2>WITH PARTNER PROGRAM</h2>
        <div class="title-desc" v-html="$t('content.programs.withp.txt01')"></div>
      </div>
    </section>
    <!-- // Title Section -->

    <!-- 주요 파트너사 -->
    <section class="grey-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3
            :class="{ kor: $i18n.locale === 'ko' }"
           v-html="$t('content.programs.withp.txt02')"></h3>
        </div>

        <div class="card-list-wrap">
          <StaggerTransition
            id="partners"
          >
            <ul class="card-list ani-stagger">
              <li
                v-for="(partner, index) in partnerList"
                :key="index"
                >
                <LogoCard
                  :name="partner.name"
                  :logo="requireAssetsImage(partner.image)"
                />
              </li>
            </ul>
          </StaggerTransition>
        </div>
      </div>
    </section>
    <!-- // 주요 파트너사 -->

    <!-- 프로그램 혜택 -->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3
            :class="{ kor: $i18n.locale === 'ko' }"
           v-html="$t('content.programs.withp.txt03')"></h3>
        </div>

        <StaggerTransition
          id="with-partner-benefits"
        >
          <ul class="icon-card-list line-type ani-stagger">
            <!-- BRAND SUPPORT -->
            <li>
              <dl class="icon-card startup">
                <dt>BRAND SUPPORT</dt>
                <dd v-html="$t('content.programs.withp.txt04')">
                </dd>
              </dl>
            </li>

            <!-- NETWORKING -->
            <li>
              <dl class="icon-card network">
                <dt>NETWORKING</dt>
                <dd v-html="$t('content.programs.withp.txt05')">
                </dd>
              </dl>
            </li>

            <!-- INVESTMENT -->
            <li>
              <dl class="icon-card investment">
                <dt>INVESTMENT</dt>
                <dd v-html="$t('content.programs.withp.txt06')">
                </dd>
              </dl>
            </li>

          </ul>
        </StaggerTransition>
      </div>
    </section>
    <!-- // 프로그램 혜택 -->

    <!-- 선발 프로세스 -->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3
            :class="{ kor: $i18n.locale === 'ko' }"
           v-html="$t('content.programs.withp.txt07')"></h3>
        </div>
        <ol class="process-list">
          <!-- 서류 접수 -->
          <li>
            <dl class="process" data-aos="fade-right">
              <dt>Step 01</dt>
              <dd v-html="$t('content.programs.withp.txt08')"></dd>
            </dl>
          </li>

          <!-- 1차 서류 심사 -->
          <li>
            <dl class="process" data-aos="fade-right" data-aos-delay="200">
              <dt>Step 02</dt>
              <dd v-html="$t('content.programs.withp.txt09')"></dd>
            </dl>
          </li>

          <!-- 2차 서류 심사 -->
          <li>
            <dl class="process" data-aos="fade-right" data-aos-delay="400">
              <dt>Step 03</dt>
              <dd v-html="$t('content.programs.withp.txt10')"></dd>
            </dl>
          </li>

          <!-- 선발 발표 -->
          <li>
            <dl class="process" data-aos="fade-right" data-aos-delay="600">
              <dt>Step 04</dt>
              <dd v-html="$t('content.programs.withp.txt11')"></dd>
            </dl>
          </li>

        </ol>
      </div>
    </section>
    <!-- // 선발 프로세스 -->

    <!-- 프로그램 참여 사례 -->
    <section class="case-section">
      <div class="inner-lg symbol-type2">
        <div class="title-wrap">
          <h3
            :class="{ kor: $i18n.locale === 'ko' }"
           v-html="$t('content.programs.withp.txt12')"></h3>
        </div>
        <div class="case-list-wrap">
          <ul class="case-list">
            <!-- 프로그램 참여 사례 1 : 웰트 -->
            <li>
              <div class="case">
                <div class="case-photo-area" data-aos="fade-right">
                  <img
                    src="@/assets/images/programs/photo-case-3.jpg"
                    class="case-photo"
                    alt="웰트 대표 강성지 님"
                  />
                </div>
                <div data-aos="fade-left">
                  <h4 v-html="$t('content.programs.withp.txt13')"></h4>
                  <span class="participant" v-html="$t('content.programs.withp.txt14')"></span>
                  <p class="case-review" v-html="$t('content.programs.withp.txt15')">
                  </p>
                  <span class="name" v-html="$t('content.programs.withp.txt16')"></span>
                </div>
              </div>
            </li>
            <!-- // 프로그램 참여 사례 1 : 웰트 -->

            <!-- 프로그램 참여 사례 2 : 코리아스타트업포럼 -->
            <li>
              <div class="case">
                <div class="case-photo-area" data-aos="fade-left">
                  <img
                    src="@/assets/images/programs/photo-case-4.jpg"
                    class="case-photo"
                    alt="코리아스타트업포럼 대표 최성진 님"
                  />
                </div>
                <div data-aos="fade-right">
                  <h4 v-html="$t('content.programs.withp.txt17')"></h4>
                  <span class="participant" v-html="$t('content.programs.withp.txt18')"></span>
                  <p class="case-review" v-html="$t('content.programs.withp.txt19')">
                  </p>
                  <span class="name" v-html="$t('content.programs.withp.txt20')"></span>
                </div>
              </div>
            </li>
            <!-- // 프로그램 참여 사례 2 : 코리아스타트업포럼 -->

            <!-- 프로그램 참여 사례 3 : 로켓뷰 -->
            <li>
              <div class="case">
                <div class="case-photo-area" data-aos="fade-right">
                  <img
                    src="@/assets/images/programs/photo-case-5.jpg"
                    class="case-photo"
                    alt="로켓뷰 대표 김화경 님"
                  />
                </div>
                <div data-aos="fade-left">
                  <h4 v-html="$t('content.programs.withp.txt21')"></h4>
                  <span class="participant" v-html="$t('content.programs.withp.txt22')"></span>
                  <p class="case-review" v-html="$t('content.programs.withp.txt23')">
                  </p>
                  <span class="name" v-html="$t('content.programs.withp.txt24')"></span>
                </div>
              </div>
            </li>
            <!-- // 프로그램 참여 사례 3 : 로켓뷰 -->

            <!-- 프로그램 참여 사례 4 : 디자이노블 -->
            <li>
              <div class="case">
                <div class="case-photo-area" data-aos="fade-left">
                  <img
                    src="@/assets/images/programs/photo-case-6.jpg"
                    class="case-photo"
                    alt="디자이노블 대표 신기영 님"
                  />
                </div>
                <div data-aos="fade-right">
                  <h4 v-html="$t('content.programs.withp.txt25')"></h4>
                  <span class="participant" v-html="$t('content.programs.withp.txt26')"></span>
                  <p class="case-review" v-html="$t('content.programs.withp.txt27')">
                  </p>
                  <span class="name" v-html="$t('content.programs.withp.txt28')"></span>
                </div>
              </div>
            </li>
            <!-- // 프로그램 참여 사례 4 : 디자이노블 -->

          </ul>
        </div>
      </div>
    </section>
    <!-- // 프로그램 참여 사례 -->

    <!-- ALUMNI -->
    <section class="grey-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3>ALUMNI</h3>
        </div>

        <!-- ALUMNI 탭 -->
        <BaseTab
          :tabValue="currentAlumniTab"
          :list="alumniTabList"
          tabtype="small-tab"
          @input="onAlumniTabChange"
        >
          <template v-slot="Scope">
            <li class="" :class="Scope.paramClass">{{Scope.title}}</li>
          </template>
        </BaseTab>
        <!-- // ALUMNI 탭 -->

        <!-- ALUMNI 슬라이더 -->
        <div class="card-list-wrap">
          <StaggerTransition
            id="alumni"
            :delay="0"
          >
            <BaseSlider
              ref="alumniSlider"
              :list="chunkedAlumniList"
              :autoplay="false"
              :preloadImages="false"
              @change="onAlumniSliderChange"
            >
              <template v-slot="{ item }">
                <ul class="card-list ani-stagger">
                  <li
                    v-for="(subItem, subIndex) in item.subList"
                    :key="subIndex"
                  >
                    <LogoCard
                      :name="subItem.name"
                      :logo="requireAssetsImage(subItem.image)"
                    />
                  </li>
                </ul>
              </template>
            </BaseSlider>
          </StaggerTransition>
        </div>
        <!-- // ALUMNI 슬라이더 -->
      </div>
    </section>
    <!-- // ALUMNI -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseTab from '@/components/base/BaseTab.vue';
import BaseSlider from '@/components/slider/BaseSlider.vue';
import LogoCard from '@/components/card/LogoCard.vue';
import _ from 'lodash';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';

/**
 * Programs > With Partner
 */
export default {
  name: 'ProgramsWithPartner',
  mixins: [
    mixinHelperUtils,
  ],

  components: {
    BaseTab,
    BaseSlider,
    LogoCard,
    StaggerTransition,
  },

  data() {
    return {
      /**
       * 주요 파트너사 리스트
       * @type {array}
       */
      partnerList: [
        {
          name: '현대',
          image: 'programs/partner/withpartner-1.png',
        },
        {
          name: '이랜드',
          image: 'programs/partner/withpartner-2.png',
        },
        {
          name: '교원',
          image: 'programs/partner/withpartner-3.png',
        },
        {
          name: '예스24',
          image: 'programs/partner/withpartner-4.png',
        },
        {
          name: '고대병원',
          image: 'programs/partner/withpartner-5.png',
        },
        {
          name: '바이엘',
          image: 'programs/partner/withpartner-6.png',
        },
        {
          name: '신패스',
          image: 'programs/partner/withpartner-7.png',
        },
        {
          name: '콘텐츠진흥원',
          image: 'programs/partner/withpartner-8.png',
        },
      ],

      /**
       * ALUMNI 리스트
       * @type {array}
       */
      alumniList: [
        // with ELAND
        {
          name: 'with ELAND',
          subList: [
            { name: 'Linder(히든트랙)', image: 'programs/alumni/eland/alumni-eland-1.png' },
            { name: '디자이노블', image: 'programs/alumni/eland/alumni-eland-2.png' },
            { name: '디파인드', image: 'programs/alumni/eland/alumni-eland-3.png' },
            { name: '로켓뷰', image: 'programs/alumni/eland/alumni-eland-4.png' },
            { name: '메이아이', image: 'programs/alumni/eland/alumni-eland-5.png' },
            { name: '몬데이터', image: 'programs/alumni/eland/alumni-eland-6.png' },
            { name: '소프트런치', image: 'programs/alumni/eland/alumni-eland-7.png' },
            { name: '아자스쿨', image: 'programs/alumni/eland/alumni-eland-8.png' },
            { name: '와이드필드', image: 'programs/alumni/eland/alumni-eland-9.png' },
            { name: '인포그린', image: 'programs/alumni/eland/alumni-eland-10.png' },
            { name: '클린그린', image: 'programs/alumni/eland/alumni-eland-11.png' },
            { name: '태그바이', image: 'programs/alumni/eland/alumni-eland-12.png' },
            { name: '땡큐마켓', image: 'programs/alumni/eland/alumni-eland-13.png' },
            { name: '코너마켓', image: 'programs/alumni/eland/alumni-eland-14.png' },
            { name: 'PLZ', image: 'programs/alumni/eland/alumni-eland-15.png' },
          ],
        },

        // with KOCCA
        {
          name: 'with KOCCA',
          subList: [
            { name: 'NEOCOMIX', image: 'programs/alumni/kocca/alumni-kocca-1.png' },
            { name: '둥글', image: 'programs/alumni/kocca/alumni-kocca-2.png' },
            { name: '보이스루', image: 'programs/alumni/kocca/alumni-kocca-3.png' },
            { name: '브랜뉴테크(비비빅)', image: 'programs/alumni/kocca/alumni-kocca-4.png' },
            { name: '브레인콜라', image: 'programs/alumni/kocca/alumni-kocca-5.png' },
            { name: '엑씽크', image: 'programs/alumni/kocca/alumni-kocca-6.png' },
            { name: '엠랩', image: 'programs/alumni/kocca/alumni-kocca-7.png' },
            { name: '클린그린', image: 'programs/alumni/kocca/alumni-kocca-8.png' },
            { name: '마블러스', image: 'programs/alumni/kocca/alumni-kocca-9.png' },
            { name: '두리번', image: 'programs/alumni/kocca/alumni-kocca-10.png' },
            { name: '애드히어로', image: 'programs/alumni/kocca/alumni-kocca-11.png' },
          ],
        },

        // with LOTTE HOMESHOPPING
        {
          name: 'with LOTTE HOMESHOPPING',
          subList: [
            { name: '아키드로우', image: 'programs/alumni/lotte/alumni-lotte-1.png' },
            { name: '엠랩', image: 'programs/alumni/lotte/alumni-lotte-2.png' },
          ],
        },

        // with ZERO1NE
        {
          name: 'with ZERO1NE',
          subList: [
            { name: 'EYERIS', image: 'programs/alumni/zero/alumni-zero-1-ne-1.png' },
            { name: '마인드아이', image: 'programs/alumni/zero/alumni-zero-1-ne-2.png' },
            { name: '몬가타', image: 'programs/alumni/zero/alumni-zero-1-ne-3.png' },
            { name: '비트센싱', image: 'programs/alumni/zero/alumni-zero-1-ne-4.png' },
            { name: '솔티드', image: 'programs/alumni/zero/alumni-zero-1-ne-5.png' },
            { name: '애니랙티브', image: 'programs/alumni/zero/alumni-zero-1-ne-6.png' },
            { name: '에이아이트릭스', image: 'programs/alumni/zero/alumni-zero-1-ne-7.png' },
            { name: '피트메디', image: 'programs/alumni/zero/alumni-zero-1-ne-8.png' },
          ],
        },

        // with 라이프스타일 1기 this.$t('content.programs.toglb.txt25')
        {
          name: this.$t('content.programs.withp.txt29'),
          subList: [
            { name: '두잉랩', image: 'programs/alumni/lifestyle-1/alumni-lifestyle-1-unit-1.png' },
            { name: '마보', image: 'programs/alumni/lifestyle-1/alumni-lifestyle-1-unit-2.png' },
            { name: '마일로', image: 'programs/alumni/lifestyle-1/alumni-lifestyle-1-unit-3.png' },
            { name: '삼쩜일사', image: 'programs/alumni/lifestyle-1/alumni-lifestyle-1-unit-4.png' },
            { name: '아토머스', image: 'programs/alumni/lifestyle-1/alumni-lifestyle-1-unit-5.png' },
            { name: '키튼플래닛', image: 'programs/alumni/lifestyle-1/alumni-lifestyle-1-unit-6.png' },
            { name: '티스쿨컴퍼니', image: 'programs/alumni/lifestyle-1/alumni-lifestyle-1-unit-7.png' },
          ],
        },

        // 라이프스타일 2기
        {
          name: this.$t('content.programs.withp.txt30'),
          subList: [
            { name: 'Trost(휴마트컴퍼니)', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-1.png' },
            { name: '딥메디', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-2.png' },
            { name: '라이앤캐처스', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-3.png' },
            { name: '룬랩', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-4.png' },
            { name: '뤼이드', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-5.png' },
            { name: '머니매그넷', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-6.png' },
            { name: '브레인콜라', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-7.png' },
            { name: '서틴스플로어', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-8.png' },
            { name: '아자스쿨', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-9.png' },
            { name: '엑씽크', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-10.png' },
            { name: '원드롭', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-11.png' },
            { name: '웨인힐스벤처스 ', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-12.png' },
            { name: '웰트 ', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-13.png' },
            { name: '유커넥 ', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-14.png' },
            { name: '임퍼펙트 ', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-15.png' },
            { name: '펫나우 ', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-16.png' },
            { name: '펫트너 ', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-17.png' },
            { name: '핑거앤 ', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-18.png' },
            { name: '더매치랩 ', image: 'programs/alumni/lifestyle-2/alumni-lifestyle-2-unit-19.png' },
          ],
        },
      ],

      /**
       * 현재 ALUMNI 탭
       * @type {number}
       */
      currentAlumniTab: 0,
    };
  },

  computed: {
    /**
     * ALUMNI 탭 리스트
     * @type {string[]}
     */
    alumniTabList() {
      return this.alumniList.map((item) => item.name);
    },

    /**
     * 분할된 ALUMNI 리스트 (12개 단위로 분할)
     * @type {array}
     */
    chunkedAlumniList() {
      return this.alumniList.reduce((list, item, index) => list.concat(
        _.chunk(item.subList, 12)
          .map((chunkedSubList) => ({
            name: item.name,
            subList: chunkedSubList,
            tabIndex: index,
          })),
      ), []);
    },
  },

  methods: {
    /**
     * ALUMNI 탭 선택 이벤트 처리
     * @param {number} tabIndex
     */
    onAlumniTabChange(tabIndex) {
      if (tabIndex >= 0) {
        const slideIndex = this.chunkedAlumniList
          .findIndex((item) => item.tabIndex === tabIndex);
        this.$refs.alumniSlider.slideTo(slideIndex);
      }
    },

    /**
     * ALUMNI 슬라이더 변경 이벤트 처리
     * @param {number} slideIndex
     */
    onAlumniSliderChange(slideIndex) {
      const { tabIndex } = this.chunkedAlumniList[slideIndex];
      this.currentAlumniTab = tabIndex;
    },
  },

};
</script>
